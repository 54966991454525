import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Images/logo";
import { MadeBy } from "../MadeBy";

function Footer(props) {
  return (
    <div className="footer">
      <div className="container-fluid">
				<div className="level ">
					<div className="level-left">
						<div className="level-item">
							<Link to="/">
								<Logo />
							</Link>
						</div>
						<div className="level-item">
							<MadeBy />
						</div>
					</div>
					<div className="level-right">
						<div className="level-item has-text-centered">
							{!props.hideCredits && (
								<div className="clearbit">
									<a
										href="https://clearbit.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Logos provided by Clearbit
									</a>
								</div>
							)}
						</div>
					</div>
				</div>
      </div>
    </div>
  );
}

export default Footer;
