import React from "react";

// import styles
import "./styles.scss";

// import animation module
import { CSSTransitionGroup } from "react-transition-group";

const SectionHeading = props => {
  return (
    <>
      <CSSTransitionGroup
        transitionName="text-animation"
        transitionAppear={true}
        transitionAppearTimeout={100}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="section-heading">
          <h2 className=""> {props.heading} </h2>
        </div>
      </CSSTransitionGroup>
    </>
  );
};

export default SectionHeading;
