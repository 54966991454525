import React from "react";

// import Categories component
// import Categories from '../Categories';
import Sidebar from "../Sidebar";
import HomePosts from "../HomePosts";

// import footer components
import Footer from "../Footer";

const MainPage = () => {
  return (
    <div>
			
      <div className="columns">
        <div className="column is-2 sidebar-width">
          <Sidebar />
        </div>
        <div className="column ">
          <main>
            <HomePosts />
          </main>
        </div>
      </div>

      <Footer hideCredits={false} />
    </div>
  );
};

export default MainPage;
