import React from "react";
import { Link } from "react-router-dom";

import PageNotFoundImage from "../Images/PageNotFound";

const PageNotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found--inner">
        <h3> 404 page not found </h3>
        <PageNotFoundImage />
        <div>
          <Link to="/">Go Home</Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
