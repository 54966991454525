import React from 'react'
import './styles.scss'

export const MadeBy = () => {
	return (
		<div className="made-by">
			<p>Website made with ReactJS by 
				<a href="https://meet-andrei.netlify.app/?ref=bookmarked" target="_blank" rel="noopener noreferrer" > Andrei C. </a>
			</p>
		</div>
	)
}
