import * as React from "react";

function PageNotFoundImage(props) {
  return (
    <svg
      width={600}
      height={427}
      viewBox="0 0 1440 1024"
      fill="none"
      {...props}
    >
      <path fill="" d="M0 0H1440V1024H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M894.245 196.092a2.937 2.937 0 012.799-2.092h.022a2.126 2.126 0 012.115 2.137l-.003.747v.016a2.556 2.556 0 01-2.507 2.555l-.376.007a2.25 2.25 0 01-2.274-2.551c.012-.088.086-.361.224-.819zm10 48a2.937 2.937 0 012.799-2.092h.022a2.126 2.126 0 012.115 2.137l-.003.747v.016a2.556 2.556 0 01-2.507 2.555l-.376.007a2.25 2.25 0 01-2.274-2.551c.012-.088.086-.361.224-.819zm-296.919-34.967c.184.42.286.672.308.756a2.272 2.272 0 01-1.954 2.827l-.425.046a2.552 2.552 0 01-2.811-2.236l-.088-.717a2.178 2.178 0 011.897-2.427 2.96 2.96 0 013.073 1.751zm-3.183 43.344c.21.48.327.768.352.864a2.597 2.597 0 01-2.233 3.23l-.487.053a2.916 2.916 0 01-3.212-2.555l-.1-.82a2.49 2.49 0 012.168-2.774 3.385 3.385 0 013.512 2.002zm102.193-146.228a5.093 5.093 0 014.88-3.637 3.75 3.75 0 013.749 3.75l.001 1.25a4.4 4.4 0 01-4.339 4.417l-.723.01a3.918 3.918 0 01-3.938-4.427c.02-.148.143-.602.37-1.363z"
        fill="#F6F0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M657 494c2.311 18.058 4.145 28.391 5.5 31 2.033 3.913 12.5 7.5 14.5 7.5 1.333 0 50.667 4.167 148 12.5 7.333 0 13.667-2.167 19-6.5 2.151-1.747 1.933-4.247 3-8 1.052-3.703 2.052-11.536 3-23.5l-25-13H657z"
        fill="#fff"
      />
      <path
        d="M604.552 206.91a2.928 2.928 0 00-2.549 3.263l.087.715a3.303 3.303 0 003.637 2.893l.425-.047a3.023 3.023 0 002.599-3.76c-.037-.141-.141-.398-.347-.868a3.711 3.711 0 00-3.852-2.196zm2.477 2.797c.162.369.263.616.269.642a1.522 1.522 0 01-1.309 1.894l-.425.047a1.803 1.803 0 01-1.985-1.579 98.7 98.7 0 00-.088-.72 1.429 1.429 0 011.244-1.592 2.21 2.21 0 012.294 1.308zm-6.099 40.297a3.24 3.24 0 00-2.821 3.61l.1.816a3.667 3.667 0 004.038 3.213l.487-.053a3.346 3.346 0 002.877-4.164c-.039-.153-.158-.446-.391-.977a4.132 4.132 0 00-4.29-2.445zm2.916 3.047c.188.429.303.711.313.75a1.847 1.847 0 01-1.588 2.298l-.486.053a2.169 2.169 0 01-2.387-1.899c-.009-.08-.042-.354-.1-.822a1.739 1.739 0 011.515-1.938 2.632 2.632 0 012.733 1.558zm293.218-60.17a3.69 3.69 0 00-3.513 2.627c-.154.511-.23.788-.25.934a3.001 3.001 0 003.032 3.402l.376-.007a3.306 3.306 0 003.243-3.306v-.014l.003-.745a2.877 2.877 0 00-2.861-2.891h-.03zm-2.276 3.762c.004-.03.078-.299.199-.703a2.19 2.19 0 012.085-1.559h.014c.76.004 1.373.623 1.369 1.384l-.003.75v.016c0 .984-.788 1.787-1.772 1.806l-.376.007a1.502 1.502 0 01-1.516-1.701zm12.276 44.238a3.69 3.69 0 00-3.513 2.627c-.154.512-.23.788-.25.934a3.001 3.001 0 003.032 3.402l.376-.007a3.306 3.306 0 003.243-3.306v-.014l.003-.745a2.877 2.877 0 00-2.861-2.891h-.03zm-2.276 3.762c.004-.03.078-.299.199-.703a2.19 2.19 0 012.085-1.559h.014c.76.004 1.373.623 1.369 1.384l-.003.75v.016c0 .984-.788 1.787-1.772 1.806l-.376.007a1.502 1.502 0 01-1.516-1.701zm-199.601-136.94a4.668 4.668 0 004.692 5.274l.722-.01a5.15 5.15 0 005.079-5.169v-1.248a4.5 4.5 0 00-4.5-4.499 5.844 5.844 0 00-5.599 4.172c-.243.814-.367 1.273-.394 1.48zm5.993-4.152a3 3 0 013 2.999v1.254a3.649 3.649 0 01-3.599 3.663l-.723.01a3.168 3.168 0 01-3.184-3.579c.012-.089.134-.539.345-1.246a4.342 4.342 0 014.161-3.101z"
        fill="#67577F"
      />
      <path
        d="M661.178 241.238l-6.332 13.261-50.146-1.503-.045 1.499 51.123 1.533 7.081-14.83 29.773-.707-.035-1.499-30.607.727-5.199-20.436-49.189-9.117-.273 1.475 48.245 8.942 5.257 20.664.347-.009z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M769.5 305.5l72.5-14-32.5 87.5h-28l-12-73.5z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M679 394.5l-115.808-27.515a56 56 0 00-46.069 9.33l-80.449 59.018a55.998 55.998 0 00-22.7 40.727L403 614.5h26l15.311-128.615a44 44 0 0116.864-29.674l73.119-56.245a28 28 0 0121.777-5.409L676 415l3-20.5z"
        fill="#fff"
      />
      <path
        d="M679.842 393.929l-116.477-27.674a56.748 56.748 0 00-46.686 9.456l-80.448 59.017a56.75 56.75 0 00-23.005 41.273L402.188 615.25h27.478l15.39-129.277a43.25 43.25 0 0116.577-29.168l73.119-56.245a27.253 27.253 0 0121.193-5.264l120.686 20.572 3.211-21.939zm-4.473 20.202l-119.172-20.313a28.751 28.751 0 00-22.36 5.553l-73.119 56.245a44.749 44.749 0 00-17.151 30.18L428.334 613.75h-24.522l10.91-137.63a55.253 55.253 0 0122.396-40.183l80.449-59.017a55.251 55.251 0 0145.452-9.206l115.14 27.357-2.79 19.06z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M676.558 531.728L669 501.5l142.5 9.5 1.63 4.225 11.866 29.005c8.568-.366 14.422-1.824 17.536-4.316 2.774-2.219 4.92-10.549 6.281-24.807L827 498.696 838 381l23.5 42-10.751 86.948a.779.779 0 01-.001.108c-1.352 17.913-3.706 28.171-7.279 31.03-3.442 2.753-9.624 4.283-18.571 4.649L825 546l-148-12.5-.062-.252a18.505 18.505 0 01-17.686-16.022l-3.04-22.545 1.486-.2 3.04 22.545a17.006 17.006 0 0015.82 14.702z"
        fill="#67577F"
      />
      <path
        d="M651 256.378l5.189 25.946c4.869 24.342 28.53 40.143 52.88 35.315L841.5 291.378l-14.709-62.234c-10.341-43.748-52.264-72.451-96.791-66.266-5.905.82-12.233 1.912-18.985 3.277-41.461 8.381-68.31 48.745-60.015 90.223z"
        fill="#fff"
      />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={649}
        y={162}
        width={193}
        height={157}
      >
        <path
          d="M651 256.378l5.189 25.946c4.869 24.342 28.53 40.143 52.88 35.315L841.5 291.378l-14.709-62.234c-10.341-43.748-52.264-72.451-96.791-66.266-5.905.82-12.233 1.912-18.985 3.277-41.461 8.381-68.31 48.745-60.015 90.223z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M650 303l104.882-17.48c10.246-1.708 17.479-10.975 16.648-21.329l-2.052-25.562c-.973-12.111-11.579-21.141-23.691-20.169a21.85 21.85 0 00-2.522.351L634 240.5l16 62.5z"
          fill="#F6F0FF"
        />
        <path
          d="M649.444 303.353l105.562-17.593c10.63-1.772 18.134-11.387 17.272-22.129l-2.053-25.562c-1.005-12.524-11.973-21.862-24.498-20.856-.875.07-1.746.191-2.608.362l-110.042 21.844 16.367 63.934zm-14.521-62.771l108.488-21.535c.805-.16 1.618-.273 2.437-.339 11.698-.939 21.943 7.783 22.882 19.481l2.053 25.562c.8 9.966-6.162 18.885-16.024 20.529l-104.203 17.367-15.633-61.065z"
          fill="#67577F"
        />
      </g>
      <path
        d="M712.216 140.355L712 112a.75.75 0 10-1.5 0l.216 29.105c0 .414.336.75.75.75h20.263l-11.428 21.903a.751.751 0 001.33.694l12-23a.75.75 0 00-.665-1.097h-20.75z"
        fill="#67577F"
      />
      <path
        d="M650.265 256.525l5.189 25.946c4.95 24.747 29.005 40.813 53.761 35.904l132.431-26.262a.749.749 0 00.584-.908l-14.709-62.233c-10.43-44.125-52.714-73.075-97.624-66.837-5.92.822-12.264 1.917-19.031 3.285-41.866 8.463-68.978 49.222-60.601 91.105zm58.658 60.378c-23.944 4.748-47.211-10.79-51.998-34.726l-5.189-25.946c-8.215-41.072 18.372-81.042 59.427-89.341 6.738-1.362 13.052-2.452 18.941-3.269 44.143-6.131 85.705 22.324 95.957 65.696l14.531 61.476-131.669 26.11z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M671.722 378.804l-16.401 106.452c-1.177 7.642 4.063 14.791 11.705 15.968.391.061.785.104 1.18.131l143.474 9.777c7.212.491 13.612-4.588 14.77-11.723l17.105-105.328c1.24-7.632-3.942-14.823-11.574-16.063a13.965 13.965 0 00-1.189-.141l-144.178-10.901c-7.291-.551-13.779 4.601-14.892 11.828z"
        fill="#fff"
      />
      <path
        d="M670.981 378.69L654.58 485.142c-1.241 8.051 4.281 15.583 12.332 16.824.412.063.827.109 1.243.138l143.474 9.776c7.598.518 14.341-4.834 15.562-12.351l17.105-105.327c1.306-8.041-4.154-15.618-12.195-16.924a14.637 14.637 0 00-1.252-.149l-144.178-10.901c-7.682-.581-14.517 4.848-15.69 12.462zm1.482.228c1.054-6.839 7.194-11.716 14.095-11.194l144.177 10.901c.377.028.753.073 1.125.133 7.224 1.174 12.128 7.98 10.955 15.203L825.71 499.288a13.25 13.25 0 01-13.979 11.096l-143.474-9.777a13.107 13.107 0 01-1.117-.124c-7.232-1.114-12.192-7.88-11.078-15.113l16.401-106.452z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M396.87 613.232c-.451 8.697 6.234 16.114 14.931 16.565.256.013.513.02.769.021l8.569.026c8.81.026 15.974-7.095 16.001-15.906v-.072c-.013-8.928-7.118-16.231-16.043-16.491l-6.818-.198c-9.201-.267-16.932 6.862-17.409 16.055zM586.636 801.79L660 766.29 567.225 701 495 730.255"
        fill="#fff"
      />
      <path
        d="M396.372 613.707c-.473 9.111 6.53 16.88 15.641 17.352.268.014.537.022.806.023l8.568.025c9.225.028 16.726-7.428 16.753-16.653v-.075c-.014-9.334-7.44-16.968-16.771-17.24l-6.818-.198c-9.608-.279-17.682 7.166-18.179 16.766zm1.497.078c.456-8.786 7.845-15.6 16.639-15.344l6.818.198c8.52.247 15.302 7.219 15.314 15.742v.069c-.025 8.396-6.852 15.183-15.249 15.157l-8.568-.025a16.302 16.302 0 01-.733-.02c-8.283-.43-14.65-7.494-14.221-15.777z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M392.002 801.791L313.502 836c-45.825-49.325-68.658-74.658-68.5-76 .158-1.342 27.158-11.342 81-30l66 71.791zM399.998 900.21L577 899l10-97H392l-40.5 17.5 5 40.5 43.498 40.21z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464 802v58l31-19 29 19v-58h-60z"
        fill="#F6F0FF"
      />
      <path
        d="M399.258 900.125l-43.464-39.756-5.445-39.819-37.043 16.143-69.608-77.23 82.514-29.838 66.311 71.414h193.943l72.062-34.87-91.398-64.321-71.63 29.651-.563-1.39 72.387-29.959 94.152 66.259-73.752 35.688-10.045 97.438-178.37 1.219-.051-.629zm-.179-2.196l-7.756-95.235-39.545 17.233 5.429 39.702 41.872 38.3zm-6.264-95.39l7.876 96.705 175.634-1.2 9.846-95.505H392.815zm-67.027-71.166l-79.483 28.742 67.392 74.771 77.035-33.571-64.944-69.942z"
        fill="#67577F"
      />
      <path
        d="M523.589 860.627a.75.75 0 001.161-.627v-58a.75.75 0 00-.75-.75h-60a.75.75 0 00-.75.75v58a.75.75 0 001.142.639l30.595-18.751 28.602 18.739zm-.339-57.877v55.862l-27.839-18.239a.75.75 0 00-.803-.012l-29.858 18.3V802.75h58.5z"
        fill="#67577F"
      />
      <path
        d="M665.747 257.487a4.5 4.5 0 018.835-1.717l2.671 13.743a4.5 4.5 0 01-8.835 1.717l-2.671-13.743zM736.184 248.661a5 5 0 019.926-1.219l1.706 13.896a5 5 0 01-9.925 1.219l-1.707-13.896z"
        fill="#fff"
      />
      <path
        d="M741.034 243.028l-.992.122a4.5 4.5 0 00-3.918 5.015l1.828 14.888a4.5 4.5 0 005.015 3.918l.992-.121a4.5 4.5 0 003.918-5.015l-1.828-14.889a4.501 4.501 0 00-5.015-3.918zm.183 1.489a3 3 0 013.343 2.612l1.828 14.888a3 3 0 01-2.612 3.344l-.992.122a3 3 0 01-3.343-2.612l-1.828-14.889a3 3 0 012.612-3.343l.992-.122zm-.435.557a3 3 0 00-2.612 3.344l1.706 13.895a3 3 0 105.955-.731l-1.706-13.896a3.001 3.001 0 00-3.343-2.612zm6.831-1.867a.75.75 0 00.157-1.492l-13.851-1.465a.75.75 0 10-.158 1.492l13.852 1.465zM663.27 253.7l13-5a.75.75 0 00-.539-1.4l-13 5a.751.751 0 00.539 1.4zm39.548 19.755c1.951-2.409 4.466-3.767 7.583-4.095 5.179-.544 6.336-.403 10.327 1.331a.751.751 0 00.598-1.376c-4.245-1.844-5.659-2.017-11.081-1.447-3.513.369-6.39 1.924-8.592 4.643a.75.75 0 001.165.944zm-32.914-21.828l-.988.156a4.5 4.5 0 00-3.74 5.149l2.346 14.815a4.5 4.5 0 005.149 3.741l.988-.157a4.499 4.499 0 003.74-5.148l-2.346-14.815a4.5 4.5 0 00-5.149-3.741zm.235 1.482a2.999 2.999 0 013.432 2.493l2.347 14.816a3 3 0 01-2.494 3.432l-.988.156a2.999 2.999 0 01-3.432-2.493l-2.347-14.816a3 3 0 012.494-3.432l.988-.156zm-.416.572a3 3 0 00-2.494 3.432l2.19 13.828a3 3 0 005.927-.939l-2.191-13.828a2.998 2.998 0 00-3.432-2.493z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M586.002 801.79h-194l-66-71.5h169l91 71.5zm-208.918 45.706c-3.451.99-7.853-3.803-9.832-10.705-1.979-6.901-.786-13.298 2.665-14.288 3.451-.989 7.853 3.803 9.832 10.705 1.979 6.902.785 13.299-2.665 14.288z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M770.534 535.453L797.738 692 788.5 867.5l-27.766 25.029a4 4 0 002.678 6.971H851c-12.464-94.081-22.551-164.248-30.262-210.5-7.449-44.688-17.659-96.92-30.628-156.696a8 8 0 00-7.818-6.304h-2.554c-.679 0-1.415.044-2.207.132a8 8 0 00-6.997 9.321z"
        fill="#fff"
      />
      <path
        d="M787.529 867.151l-27.535 24.821a4.749 4.749 0 003.18 8.278h87.588a.751.751 0 00.744-.849c-12.466-94.088-22.554-164.262-30.266-210.524-7.452-44.701-17.663-96.945-30.635-156.732a8.75 8.75 0 00-8.551-6.895H779.5c-.708 0-1.471.046-2.291.137a8.749 8.749 0 00-7.653 10.194l27.191 156.464-9.218 175.106zm-.016.31l.247-.518-.231.208-.016.31zm62.393 31.289h-86.732a3.25 3.25 0 01-2.176-5.664l27.766-25.029a.746.746 0 00.247-.518l9.238-175.5-.01-.167-27.205-156.547a7.25 7.25 0 016.341-8.447 19.148 19.148 0 012.125-.128h2.554a7.25 7.25 0 017.085 5.713c12.967 59.765 23.174 111.985 30.621 156.66 7.686 46.103 17.734 115.98 30.146 209.627z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M791.1 690.394c-.919-7.723 4.587-14.733 12.308-15.669l5.11-.619c7.743-.939 14.822 4.468 15.954 12.185l.04.295c.937 7.519-4.4 14.375-11.919 15.312l-5.864.73-.076.01c-7.676.913-14.639-4.569-15.553-12.244zm8.995-1.36a6.17 6.17 0 006.863 5.373l2.735-.33a5.956 5.956 0 005.176-6.802 6.341 6.341 0 00-7.009-5.351l-2.402.282a6.122 6.122 0 00-5.367 6.794l.004.034z"
        fill="#F6F0FF"
      />
      <path
        d="M789.989 690.837c-.968-8.134 4.831-15.516 12.963-16.502l5.109-.619c8.147-.988 15.596 4.701 16.787 12.821.015.103.029.207.042.311.988 7.93-4.64 15.16-12.571 16.148l-5.863.731-.081.01c-8.087.962-15.423-4.813-16.386-12.9zm1.49-.178c.864 7.265 7.454 12.453 14.719 11.588l.072-.008 5.864-.731c7.108-.886 12.153-7.366 11.267-14.475a8.445 8.445 0 00-.038-.278c-1.072-7.315-7.782-12.44-15.121-11.55l-5.11.619c-7.31.886-12.523 7.523-11.653 14.835zm7.501-1.217a6.873 6.873 0 016.025-7.627l2.402-.281a7.09 7.09 0 017.864 6.181 6.706 6.706 0 01-5.854 7.461l-2.735.33a6.92 6.92 0 01-7.698-6.026l-.004-.038zm7.522 4.575l2.736-.33a5.206 5.206 0 004.523-5.945 5.592 5.592 0 00-6.18-4.719l-2.401.282a5.373 5.373 0 00-4.71 5.963l.003.029a5.421 5.421 0 006.029 4.72z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M730.351 532.131L681 690.324l72.912 134.175-4.54 46.638a4 4 0 006.578 3.43l67.462-57.568-16-16-102.85-109.735 44.821-155.655a7.528 7.528 0 00-7.233-9.61c-.705 0-1.431-.043-2.176-.129a9 9 0 00-9.623 6.261z"
        fill="#fff"
      />
      <path
        d="M753.603 824.857l-.087-.43-.023.228.11.202zm-4.627 46.207a4.75 4.75 0 007.811 4.074l67.462-57.568a.75.75 0 00.044-1.101l-16-16L705.75 691.062l44.704-155.246a8.277 8.277 0 00-7.954-10.567c-.676 0-1.373-.041-2.09-.124a9.751 9.751 0 00-10.425 6.782L680.634 690.1a.752.752 0 00.057.582l72.802 133.973-4.517 46.409zm6.837 2.933a3.25 3.25 0 01-5.344-2.787l4.54-46.638a.752.752 0 00-.088-.431l-72.76-133.897 49.256-157.89a8.251 8.251 0 018.821-5.739c.774.09 1.528.134 2.262.134a6.776 6.776 0 016.512 8.652l-44.821 155.655a.753.753 0 00.173.721l102.851 109.735 15.443 15.444-66.845 57.041z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M677.257 695.01c-1.447-7.529 3.36-14.843 10.845-16.503l5.329-1.181c7.235-1.603 14.429 2.856 16.211 10.05 1.77 7.145-2.581 14.374-9.724 16.155l-6.701 1.671c-.23.057-.461.108-.694.153-7.072 1.359-13.907-3.272-15.266-10.345zm8.981-1.525a5.748 5.748 0 006.942 4.439l3.327-.78a5.57 5.57 0 004.117-6.843 6.034 6.034 0 00-7.063-4.372l-2.728.567a5.92 5.92 0 00-4.595 6.989z"
        fill="#F6F0FF"
      />
      <path
        d="M676.623 695.462c-1.524-7.929 3.538-15.63 11.42-17.377l5.328-1.181c7.633-1.692 15.222 3.012 17.102 10.601 1.869 7.547-2.726 15.183-10.271 17.064l-6.701 1.67c-.243.061-.488.115-.734.162-7.479 1.437-14.707-3.46-16.144-10.939zm1.473-.283c1.281 6.665 7.723 11.03 14.388 9.749.22-.042.438-.09.654-.144l6.701-1.671c6.742-1.681 10.848-8.503 9.178-15.247-1.684-6.799-8.483-11.013-15.321-9.498l-5.329 1.181c-7.089 1.572-11.641 8.499-10.271 15.63zm7.51-1.234a6.67 6.67 0 015.177-7.874l2.728-.567a6.786 6.786 0 017.982 5.082 6.322 6.322 0 01-4.712 7.598l-3.327.78-.175.038a6.497 6.497 0 01-7.673-5.057zm1.469-.302a4.998 4.998 0 006.036 3.86l3.328-.78a4.822 4.822 0 003.594-5.794l-.032-.127a5.283 5.283 0 00-6.184-3.829l-2.729.567a5.17 5.17 0 00-4.013 6.103z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M579.977 597.219a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.008 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm-45-109a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.008 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm464-22a11.051 11.051 0 0110.663-7.918c4.54.026 8.22 3.703 8.25 8.246.01 1.481.01 2.349.01 2.605v.019a9.393 9.393 0 01-9.32 9.468l-1.82.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm-98 165a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.009 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm129.003 60a11.045 11.045 0 0110.66-7.918c4.54.026 8.22 3.703 8.25 8.246.01 1.481.01 2.349.01 2.605v.019a9.393 9.393 0 01-9.32 9.468l-1.82.014a8.544 8.544 0 01-8.54-9.615c.04-.312.29-1.252.76-2.819zm-108.003-388a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.009 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm-591 256a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.008 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zm120-218a11.052 11.052 0 0110.663-7.918 8.296 8.296 0 018.247 8.246c.008 1.481.014 2.349.017 2.605v.019a9.396 9.396 0 01-9.322 9.468l-1.822.014a8.54 8.54 0 01-8.538-9.615c.039-.312.291-1.252.755-2.819zM397.694 686.351c-8.539-5.561-14.393-14.987-14.967-25.942-.954-18.201 13.027-33.728 31.228-34.682 18.2-.954 33.728 13.027 34.682 31.228.584 11.158-4.444 21.312-12.618 27.718l-12.325-15.752a12.988 12.988 0 004.97-10.92c-.376-7.169-6.493-12.677-13.663-12.301-7.169.375-12.677 6.492-12.301 13.662a12.982 12.982 0 005.896 10.22l-10.902 16.769z"
        fill="#F6F0FF"
      />
      <path
        d="M398.381 687.704l-.629-.41c-8.985-5.851-14.735-15.619-15.307-26.532-.975-18.614 13.323-34.494 31.937-35.47 18.615-.975 34.495 13.324 35.471 31.938.582 11.117-4.306 21.608-12.904 28.348l-.591.463-13.25-16.934.59-.462a12.23 12.23 0 004.684-10.29c-.354-6.756-6.118-11.946-12.874-11.592-6.757.354-11.946 6.118-11.592 12.874a12.23 12.23 0 005.556 9.631l.628.409-11.719 18.027zm-.435-2.084l10.091-15.521a13.723 13.723 0 01-5.619-10.383c-.398-7.584 5.428-14.054 13.011-14.451 7.584-.398 14.054 5.428 14.451 13.011a13.727 13.727 0 01-4.679 11.071l11.408 14.581c7.853-6.453 12.289-16.25 11.746-26.62-.933-17.787-16.107-31.45-33.894-30.518-17.787.932-31.45 16.107-30.518 33.894.533 10.174 5.774 19.304 14.003 24.936z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M864.464 435.362l189.136-74.287c4.11-1.616 8.76.409 10.37 4.521.2.495.34 1.009.43 1.533l37.71 213.384a4.998 4.998 0 01-6.84 5.488L859.202 488v24l238.358 103.988c12.15 5.301 26.3-.251 31.6-12.4a23.946 23.946 0 001.7-13.344L1093 350.7c-2.5-15.82-17.35-26.617-33.17-24.117a29.02 29.02 0 00-6.82 1.958l-197.268 83.89a11.001 11.001 0 00-6.665 10.943c.104 1.385.312 2.76.625 4.126l.031.132c1.385 5.915 7.303 9.587 13.218 8.202a11.092 11.092 0 001.513-.472z"
        fill="#fff"
      />
      <path
        d="M865.037 436.059l189.133-74.287c3.73-1.464 7.94.371 9.4 4.097.18.449.31.914.39 1.389l37.71 213.384a4.246 4.246 0 01-5.81 4.665l-237.11-98.431v25.614l238.81 104.185c12.53 5.466 27.12-.26 32.58-12.788 1.89-4.326 2.5-9.1 1.76-13.761l-37.86-239.544c-2.56-16.229-17.8-27.305-34.03-24.74-2.4.38-4.75 1.054-7 2.008l-197.263 83.89a11.749 11.749 0 00-7.119 11.689c.106 1.423.32 2.835.641 4.238l.032.135c1.48 6.319 7.801 10.241 14.12 8.761a11.72 11.72 0 001.616-.504zm-4.787 53.063l235.03 97.571c1.02.42 2.13.542 3.21.351a5.748 5.748 0 004.66-6.663l-37.71-213.384a8.859 8.859 0 00-.47-1.676c-1.77-4.498-6.85-6.712-11.34-4.945l-189.142 74.287c-.458.18-.93.327-1.409.44-5.512 1.29-11.027-2.131-12.318-7.643l-.03-.128a27.08 27.08 0 01-.608-4.015 10.25 10.25 0 016.211-10.196L1053.6 329.23a28.288 28.288 0 016.65-1.907c15.41-2.435 29.87 8.083 32.31 23.494l37.86 239.543a23.29 23.29 0 01-1.65 12.927c-5.14 11.769-18.84 17.148-30.61 12.013L860.25 511.508v-22.386z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M866.515 579.824c-9.571-3.498-17.395-11.366-20.419-21.911-5.024-17.519 5.106-35.794 22.626-40.818 17.519-5.023 35.794 5.107 40.817 22.626 3.08 10.741.464 21.766-6.058 29.847l-15.554-12.577a12.982 12.982 0 002.387-11.757c-1.979-6.902-9.178-10.892-16.08-8.913-6.901 1.979-10.892 9.178-8.913 16.079a12.984 12.984 0 008.044 8.632l-6.85 18.792z"
        fill="#F6F0FF"
      />
      <path
        d="M866.929 580.859l-.705-.258c-10.071-3.68-17.871-11.904-20.883-22.408-5.138-17.918 5.222-36.608 23.14-41.746 17.917-5.138 36.607 5.223 41.745 23.14 3.069 10.702.666 22.023-6.196 30.524l-.471.585-16.72-13.52.471-.583a12.233 12.233 0 002.249-11.08c-1.865-6.503-8.649-10.263-15.152-8.399-6.504 1.865-10.264 8.649-8.399 15.152a12.228 12.228 0 007.58 8.134l.704.257-7.363 20.202zm5.448-19.326a13.726 13.726 0 01-7.811-8.853c-2.093-7.3 2.128-14.914 9.427-17.008 7.3-2.093 14.915 2.128 17.008 9.428a13.73 13.73 0 01-2.069 11.84l14.396 11.64c6.2-8.054 8.318-18.598 5.456-28.579-4.909-17.122-22.769-27.021-39.89-22.112-17.121 4.91-27.021 22.769-22.111 39.89 2.808 9.793 9.969 17.51 19.253 21.147l6.341-17.393z"
        fill="#67577F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M871.838 522.398c8.624-1.216 14.628-9.193 13.412-17.817a16.222 16.222 0 00-.126-.759l-1.61-8.416c-1.655-8.654-10.012-14.327-18.666-12.672l-.071.013c-8.762 1.717-14.575 10.085-13.127 18.895l1.107 6.731c1.493 9.083 9.966 15.311 19.081 14.025z"
        fill="#fff"
      />
      <path
        d="M871.766 522.914c9.034-1.275 15.324-9.631 14.049-18.665a15.314 15.314 0 00-.132-.795l-1.61-8.416c-1.733-9.061-10.483-15.001-19.543-13.268l-.074.014c-9.16 1.795-15.237 10.542-13.723 19.753l1.106 6.73c1.56 9.486 10.409 15.99 19.927 14.647zm-.21-1.485c-8.711 1.229-16.809-4.724-18.236-13.405l-1.107-6.731c-1.383-8.41 4.167-16.398 12.531-18.037l.067-.013c8.248-1.577 16.212 3.83 17.789 12.077l1.61 8.416c.046.24.086.481.12.723 1.159 8.213-4.56 15.811-12.774 16.97z"
        fill="#67577F"
      />
      <path
        d="M696.4 383.018l124.521 8.996a7 7 0 016.432 7.921l-11.998 88.678a7 7 0 01-7.598 6.03l-126.417-11.99a7 7 0 01-6.249-8.089l13.894-85.684a7.002 7.002 0 017.415-5.862zm-14.388 92.593l126.441 12.168 12.101-88.773L695.895 390l-13.883 85.611zM730.06 439a9 9 0 110-18 9 9 0 010 18zm-13.781 10.738l64.842 4.534-.279 3.99L716 453.728l.279-3.99zm54.997-7.772l-8.181-9.75 9.75-8.182 8.181 9.75-9.75 8.182z"
        fill="#F6F0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1062 326.999c6.39-.58 11.22-.08 14.5 1.5 3.28 1.58 7.45 5.414 12.5 11.5l-26 23.5c-1.33-1-2.33-1.666-3-2-.67-.333-1.83-.666-3.5-1l5.5-33.5zm-535.457 44.487c4.329-2.238 8.314-3.734 11.957-4.487 3.643-.753 9.31-1.253 17-1.5l-6.5 29-3.5.5-3.469 1.138-15.488-24.651zm-112.147 101.08c1.38-5.898 2.748-10.42 4.104-13.567 1.357-3.147 3.89-7.676 7.601-13.587L449 470.499l-1.598 3.154-1.057 3.494-31.949-4.581zM1131.5 593.499c-1.71 8.476-3.88 14.143-6.5 17-2.62 2.858-7.79 5.191-15.5 7l-12-31c1.83-.542 3-1.074 3.54-1.596.53-.521 1.02-1.489 1.46-2.904l29 11.5z"
        fill="#67577F"
      />
      <path
        d="M838.013 237.678l9.49 14.508 56.386-8.429.222 1.484-57.337 8.571-10.136-15.495-34.564 3.429a.751.751 0 01-.148-1.493l34.685-3.441 3.034-23.883 54.129-17.145a.75.75 0 01.452 1.43l-53.213 16.855-3 23.609zM696.434 465.746a.75.75 0 01-.674-.867l9.5-58.5a.75.75 0 01.797-.628l99.5 7.5a.75.75 0 01.686.853l-8.5 60a.75.75 0 01-.809.642l-100.5-9zm10.196-58.447l-9.26 57.025 98.988 8.865 8.287-58.502-98.015-7.388zm-10.629-24.808l124.521 8.996a7.75 7.75 0 017.122 8.769l-11.999 88.679a7.749 7.749 0 01-8.411 6.676L680.816 483.62a7.749 7.749 0 01-6.918-8.956l13.895-85.684a7.75 7.75 0 018.208-6.489zm-.108 1.496a6.25 6.25 0 00-6.62 5.234l-13.894 85.683a6.25 6.25 0 005.579 7.223l126.417 11.991a6.251 6.251 0 006.784-5.385l11.998-88.678a6.25 6.25 0 00-5.743-7.072l-124.521-8.996zm-15.202 92.514l14.121-87.078 126.14 9.113-12.306 90.279-127.955-12.314zm126.664 10.683l11.895-87.267-123.177-8.899-13.645 84.143 124.927 12.023zM730 439.749c-5.384 0-9.75-4.365-9.75-9.75 0-5.384 4.366-9.75 9.75-9.75 5.385 0 9.75 4.366 9.75 9.75a9.75 9.75 0 01-9.75 9.75zm0-1.5a8.25 8.25 0 008.25-8.25 8.25 8.25 0 10-16.5 0 8.25 8.25 0 008.25 8.25zm-14.477 10.688l66.338 4.638-.383 5.487-66.338-4.639.383-5.486zm1.218 4.094l63.345 4.43.174-2.494-63.345-4.429-.174 2.493zm54.383-10.009l-9.146-10.9 10.899-9.145 9.146 10.899-10.899 9.146zm8.786-9.331l-7.218-8.601-8.601 7.217 7.217 8.601 8.602-7.217zM800 868.249v-1.5 1.5zm0-1.5c1 0 1 1.5 0 1.5h-11.5c-1 0-1-1.5 0-1.5h23c1 0 1 1.5 0 1.5H800c-1 0-1-1.5 0-1.5zm-35.768-49.425l-.963-1.15.963 1.15zm0 0l-9.25 7.75c-.766.642-1.73-.508-.963-1.15l9.25-7.75 9.25-7.75c.766-.642 1.73.508.963 1.15l-9.25 7.75z"
        fill="#67577F"
      />
    </svg>
  );
}

export default PageNotFoundImage;
