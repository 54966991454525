import React from "react";
import "./styles.scss";
import categories from "../../data/posts";
import SectionHeading from "../SectionHeading";
import PostThumb from "../PostThumb";
import ScrollTop from "../ScrollTop";
import { Provider, LikeButton } from "@lyket/react";
import NewBadge from "../Badges"

const HomePosts = () => {
  return (
    <>
      {categories.map((main, index) => (
        <div key={index}>
          <SectionHeading heading={main.name} />

          <div className="posts-grid">
            {main.items
							.sort((a, b) => a.title.localeCompare(b.title))
							.map((item, index) => (
              <div key={index} className="grid-item" id={item.id}>
                <a
                  className="posts-grid__item"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PostThumb key={item.id} alt={item.title} image={item.icon} />
                  <div>
										<NewBadge resource={item} />
                    <h3 className="post-title">{item.title}</h3>
                    <p className="post-description">{item.subtitle}</p>
                  </div>
                </a>
								<a
                    href={item.link}
                    className="external-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Visit Website"
                  >
                    <span className="screen-reader">Visit Website</span>
                    <svg
                      data-v-81d25d52=""
                      xmlns=" "
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="external-link__icon"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline points="15 3 21 3 21 9"></polyline>
                      <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </a>
									
									<div className="like-button-wrap">
										<Provider 
											apiKey="OL6MH917H8AhSixYGtj3R5Bfo6Y="
											theme={{
												colors: {
													background: "transparent",
													primary: "#eff0f3",
													text: "#333",
													icon: "#de1186",
													highlight: "red"
												}
											}}
										>
											<LikeButton
												key={index}
												namespace="post-like"
												id={item.id}
											/>
										</Provider>	
									</div>

              </div>
            ))}
          </div>
        </div>
      ))}
      <ScrollTop scrollStepInPx="50" delayInMs="20" />
    </>
  );
};

export default HomePosts;
