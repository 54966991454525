import React from 'react'

const PostThumb = ({ image, alt }) => {
	var errorImage = "/bookmarked-logo.svg"

	return (
		<>
			<img 
				className="post-icon" 
				src={image} 
				alt={alt} 
				loading="lazy"
				width="45px" 
				onError={(e) => {
					e.target.onerror = null;
					e.target.src = errorImage
				}}
			/>
		</>
	)
}

export default PostThumb