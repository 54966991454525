import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import categories from "../../data/posts";

// import styles
import "./styles.scss";

// import header
import Header from "../Header";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: categories,
    };
  }
  render() {
    const { categories } = this.state;
    return (
      <aside>
        <Header />

        <div className="container sidebar-container">
          {categories.map((category, index) => (
            <NavLink
              to={`/category/${index + 1}`}
              className="item"
							activeClassName="active-link"
							key={index}
            >
              <button className="item__inner">
                <img
                  className="item__icon"
                  src={category.icon}
                  alt={category.name}
                  width="30"
                />
                <span className="item__name">{category.name}</span>
              </button>
            </NavLink>
          ))}
        </div>
      </aside>
    );
  }
}

export default Sidebar;
