import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

import { Logo } from '../Images/logo'

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Link to="/">
					<Logo />
        </Link>

				<div className="sidebar-heading">
          <h1>Tools &amp; Resources</h1>
        </div>
				
      </div>
    );
  }
}

export default Header;
