import React, { useState, useEffect } from 'react';
import "./styles.scss";

const NewBadge = ({ resource }) => {
  if (!resource || !resource.createdAt) {
    return null;
  }

  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    const currentTime = new Date();
    const creationTime = new Date(resource.createdAt);
    const diffTime = currentTime - creationTime;
    const twoMonths = 5259492000; // 2months - 5.259.492.000ms

    if (diffTime > twoMonths) {
      setShowBadge(false);
    }
  }, [resource.createdAt]);

  return (
    showBadge && (
      <span className="badge badge--new">New</span>
    )
  );
};


export default NewBadge;