import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect  } from 'react-router-dom'

// import pages
import MainPage from './components/Pages/index'
import PageNotFound from './components/Pages/404'
import CategoryPage from './components/Pages/CategoryPage'

import ScrollRestoration from './components/ScrollRestoration'
import DarkModeToggle from "./components/DarkMode/DarkModeToggle";

// import google analytics
import ReactGA from 'react-ga';

// initialize google analytics
ReactGA.initialize('UA-146468457-2');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component {
  
  render() {
    return (
      <Router>
				<ScrollRestoration />
				<DarkModeToggle />

        <Switch>
					
          <Route exact path="/" component={MainPage} />
          <Route exact path="/404" component={PageNotFound} />

          <Route exact path="/category/:categoryId" component={CategoryPage} />

          <Redirect to="/404" />

        </Switch>        
      </Router>
    )
  }
}

export default App;
