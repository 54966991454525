const posts = [
  {
    name: "Inspiration",
    catID: 1,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042960/tools-and-resources%20web/inspiration_jlsmq1.svg",
    items: [
      // {
      //   title: "aaa ttest",
      //   icon: "https://logo.clearbit.com/landingfolio.com",
      //   subtitle: "Landing page examples",
      //   link: "https://www.landingfolio.com/",
			// 	id: "test",
			// 	createdAt: "2023-02-05T20:45:00.171Z"
      // },	
      {
        title: "Landingfolio",
        icon: "https://logo.clearbit.com/landingfolio.com",
        subtitle: "Landing page examples",
        link: "https://www.landingfolio.com/",
				id: "landingfolio"
      },
      {
        title: "Land-book",
        icon: "https://logo.clearbit.com/land-book.com/",
        subtitle: "The finest hand-picked website inspirations",
        link: "https://land-book.com/",
				id: "landbook"
      },
      {
        title: "Lapa Ninja",
        icon: "https://logo.clearbit.com/lapa.ninja/",
        subtitle: "Landing page examples",
        link: "https://www.lapa.ninja/",
				id: "lapaninja"
      },
      {
        title: "Pixels",
        icon: "https://logo.clearbit.com/klart.io",
        subtitle: "Kick-ass designs and copywriting",
        link: "https://klart.io/pixels",
				id: "pixels"
      },
      {
        title: "httpster",
        icon: "https://logo.clearbit.com/httpster.net/",
        subtitle: "Inspiration resource showcasing totally rocking websites made by people from all over the world",
        link: "https://httpster.net/",
				id: "httpster"
      },
      {
        title: "CSS Design Awards",
        icon: "https://logo.clearbit.com/cssdesignawards.com/",
        subtitle: "Website Awards",
        link: "https://www.cssdesignawards.com/",
				id: "cssdesignawards"
      },
      {
        title: "Collect UI",
        icon: "https://logo.clearbit.com/collectui.com/",
        subtitle: "Daily inspiration collected from daily ui archive and beyond. Hand picked, updating daily",
        link: "https://collectui.com/",
				id: "collectui"
			},
      {
        title: "Email Love",
        icon: "https://logo.clearbit.com/emaillove.com/",
        subtitle: "Browse Email Inspiration, find Email Templates or discover Great Newsletters",
        link: "https://emaillove.com/",
				id: "emailLove"
      },
      {
        title: "SaaS landing page examples",
        icon: "https://logo.clearbit.com/https://saaslandingpage.com/",
        subtitle: "Discover the best landing page examples created by top-class SaaS companies.",
        link: "https://saaslandingpage.com/",
				id: "saaslanding"
      },
      {
        title: "Site Inspire",
        icon: "https://logo.clearbit.com/https://www.siteinspire.com/",
        subtitle: "Web design inspiration.",
        link: "https://www.siteinspire.com/",
				id: "siteinspire"
      },
      {
        title: "Admire The Web",
        icon: "https://logo.clearbit.com/https://www.admiretheweb.com/",
        subtitle: "The very best in web design inspiration.",
        link: "https://www.admiretheweb.com/",
				id: "admiretheweb"
      },
      {
        title: "Commerce Cream",
        icon: "https://logo.clearbit.com/https://commercecream.com/",
        subtitle: "Curating the Best of Shopify.",
        link: "https://commercecream.com/",
				id: "commercecream"
      },
      {
        title: "Lookup design",
        icon: "https://logo.clearbit.com/lookup.design",
        subtitle: "Handpicked designs from the best-designed websites around.",
        link: "https://lookup.design/",
				id: "test",
				createdAt: "2023-02-06T20:45:00.171Z"
      },
      {
        title: "Designvault",
        icon: "https://logo.clearbit.com/designvault.io",
        subtitle: "Design Inspiration & UI Patterns From Real Products.",
        link: "https://designvault.io/",
				id: "test",
				createdAt: "2023-02-06T20:45:00.171Z"
      }			
    ]
  },
  {
    name: "Colors",
    catID: 2,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/colors_rkjxeg.svg",
    items: [
      {
        title: "Color Hunt",
        icon: "https://logo.clearbit.com/colorhunt.co",
        subtitle: "Colors pallettes for designers and artists.",
        // id: "1",
        link: "https://colorhunt.co",
				id: "colorHunt"
      },
      {
        title: "My color space",
        icon: "https://logo.clearbit.com/mycolor.space",
        subtitle: "Never waste Hours on finding the perfect Color Palette again!",
        // id: "2",
        link: "https://mycolor.space",
				id: "mycolorspace"
      },
      {
        title: "Use all five",
        icon: "https://logo.clearbit.com/abc.useallfive.com/",
        subtitle: "Accessible brand colors.",
        // id: "3",
        link: "https://abc.useallfive.com/",
				id: "useallfive"
      },
      {
        title: "Khroma",
        icon: "https://logo.clearbit.com/khroma.co/",
        subtitle: "The AI color tool for designers.",
        // id: "4",
        link: "http://khroma.co/",
				id: "khroma"
      },
      {
        title: "Culrs",
        icon: "https://logo.clearbit.com/culrs.com/",
        subtitle: "Colors that work together are curated and fashioned into quality palettes that you can incorporate.",
        // id: "6",
        link: "https://culrs.com/",
				id: "culrs"
      },
      {
        title: "Coolors",
        icon: "https://logo.clearbit.com/coolors.co/",
        subtitle: "The super fast color schemes generator!",
        // id: "7",
        link: "https://coolors.co",
				id: "coolors"
      },
      {
        title: "Brandcolors",
        icon: "https://logo.clearbit.com/brandcolors.net/",
        subtitle: "The biggest collection of official brand color codes around.",
        // id: "8",
        link: "https://brandcolors.net",
				id: "brandcolors"
      },
      {
        title: "Happy Hues",
        icon: "https://logo.clearbit.com/https://www.happyhues.co/",
        subtitle: "Curated colors in context.",
        // id: "10",
        link: "https://www.happyhues.co/",
				id: "happyhues"
      },
      {
        title: "Colorsinspo",
        icon: "https://logo.clearbit.com/https://colorsinspo.com/",
        subtitle: "All in one resource to find everything about colors with extreme ease.",
        // id: "11",
        link: "https://colorsinspo.com/",
				id: "colorsinspo"
      },
      {
        title: "Branition Colors",
        icon: "https://logo.clearbit.com/branition.com/",
        subtitle: "Explore the finest hand-curated color paletts best fitted for logos & branding.",
        // id: "11",
        link: "https://branition.com/colors",
				id: "branition",
				createdAt: "2023-02-05T20:45:00.171Z"
      }
    ]
  },
  {
    name: "Icons",
    catID: 3,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042534/tools-and-resources%20web/icons_r8rza3.svg",
    items: [
      {
        title: "Icons8",
        icon: "https://logo.clearbit.com/icons8.com/",
        subtitle: "91,000 collection of free icons",
        link: "https://icons8.com/icons",
				id: "iconseight"
      },
      {
        title: "Vivid",
        icon: "https://webkul.github.io/vivid/assets/res/vivid-favicon-57x57.png?1.0.1",
        subtitle: "Open Source SVG Icons Pack JavaScript Library",
        link: "https://webkul.github.io/vivid/",
				id: "vivid"
      },
      {
        title: "Ikonate",
        icon: "https://ikonate.com/favicon.png",
        subtitle: "Fully customisable & accessible vector icons",
        link: "https://www.ikonate.com/",
				id: "ikonate"
      },
      {
        title: "Thenounproject",
        icon: "https://logo.clearbit.com/thenounproject.com",
        subtitle: "Icons for everything",
        link: "https://thenounproject.com/",
				id: "thenounprj"
      },
      {
        title: "Icon store",
        icon: "https://logo.clearbit.com/iconstore.co",
        subtitle: "Free Icons by First-Class Designers",
        link: "https://iconstore.co",
				id: "iconstore"
      },
      {
        title: "Simple Icons",
        icon: "https://logo.clearbit.com/simpleicons.org/",
        subtitle: "Free SVG icons for popular brands",
        link: "https://simpleicons.org/",
				id: "simpleIcons"
      },
      // {
      //   title: "Illustrio",
      //   icon: "https://logo.clearbit.com/illustrio.com/",
      //   subtitle: "100% free, 100% customizable icon library",
      //   link: "https://illustrio.com/",
			// id: "landingfolio"
      // },
      {
        title: "Icon SVG",
        icon: "https://iconsvg.xyz/favicon-32.png",
        subtitle: "Quick customizable SVG icons for your projects",
        link: "https://iconsvg.xyz/",
				id: "iconSVG"
      },
      {
        title: "Remix Icon",
        icon: "https://remixicon.com/favicon.ico",
        subtitle: "Simply Delightful Icon System",
        link: "https://remixicon.com/",
				id: "remixicon"
      },
      {
        title: "Potlab icons",
        icon: "/images/potlab-logo.png",
        subtitle: "Animated SVG icons for web projects",
        link: "http://www.potlabicons.com/index.html",
				id: "potlabicons"
      },
      {
        title: "500+ CSS Icons",
        icon: "https://css.gg/fav/apple-icon-60x60.png",
        subtitle: "Minimalistic icon library Designed by code",
        link: "https://css.gg/",
				id: "cssicons"
			},
			{
        title: "Lordicon",
        icon: "https://logo.clearbit.com/https://lordicon.com/",
        subtitle: "Interactive, animated web icons for the unforgettable website experience.",
        link: "https://lordicon.com/",
				id: "lordicon"
      },
			{
        title: "Icon Scout",
        icon: "https://logo.clearbit.com/https://iconscout.com/",
        subtitle: "High-quality Icons, Illustrations, and Stock photos at one place.",
        link: "https://iconscout.com/",
				id: "iconscout"
      },
			{
        title: "Hero Icons",
        icon: "https://logo.clearbit.com/heroicons.com",
        subtitle: "Beautiful hand-crafted SVG icons, by the makers of Tailwind CSS.",
        link: "https://heroicons.com/",
				id: "heroicons"
      },
			{
        title: "Tabler Icons",
        icon: "https://tablericons.com/favicon.png",
        subtitle: "Fully customizable free SVG icons.",
        link: "https://tablericons.com/",
				id: "tablericons"
      },
			{
        title: "Gitmoji",
        icon: "https://gitmoji.carloscuesta.me/static/apple-icon-114x114.png",
        subtitle: "An emoji guide for your commit messages.",
        link: "https://gitmoji.carloscuesta.me/",
				id: "gitmoji"
      }
    ]
  },
  // {
  //   name: "Components",
  //   catID: 4,
  //   icon:
  //     "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568041634/tools-and-resources%20web/components_sfgexc.svg",
  //   items: [
  //     {
  //       title: "Free front end",
  //       icon: "https://logo.clearbit.com/freefrontend.com",
  //       subtitle: "Free front end",
  //       id: "1",
  //       link: "https://freefrontend.com/",
	// id: "landingfolio"
  //     }
  //   ]
	// },

  {
    name: "Fonts",
    catID: 4,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1598549015/tools-and-resources%20web/fonts_f2ka1d.svg",
    items: [
      {
        title: "Dev Fonts",
        icon: "https://devfonts.gafi.dev/apple-touch-icon.png",
        subtitle: "Interactive list of the best fonts for developers with options to preview fonts and themes.",
        link: "https://devfonts.gafi.dev/",
				id: "devfonts"
			},
      {
        title: "FontBase",
        icon: "https://logo.clearbit.com/fontba.se",
        subtitle: "FontBase is the font manager of the new generation, built by designers, for designers",
        link: "https://fontba.se",
				id: "fontbase",
				createdAt: "2023-02-06T20:45:00.171Z"
			}			
    ]
  },
  {
    name: "Templates",
    catID: 5,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/templates_r12wog.svg",
    items: [
      {
        title: "Freebie Supply",
        icon: "https://logo.clearbit.com/freebiesupply.com",
        subtitle: "The freshest design resources for Adobe XD, Figma, Sketch, Photoshop and Adobe Illustrator",
        link: "https://freebiesupply.com",
				id: "freebiesupply"
      },
      {
        title: "Cruip",
        icon: "https://logo.clearbit.com/cruip.com",
        subtitle: "Landing page templates for startups",
        // id: "2",
        link: "https://cruip.com/",
				id: "cruip"
      },
      {
        title: "Freisbug",
        icon: "https://cdn.freebiesbug.com/wp-content/themes/freebiesbug/assets/images/logo.png",
        subtitle: "Hand-picked resources for web designer and developers, constantly updated",
        // id: "3",
        link: "https://freebiesbug.com",
				id: "freisbug"
      },
      {
        title: "One page love",
        icon: "https://logo.clearbit.com/onepagelove.com",
        subtitle: "One Page websites, templates and resources",
        // id: "5",
        link: "https://onepagelove.com/",
				id: "onepagelove"
      },
      {
        title: "WordPress themes",
        icon: "https://logo.clearbit.com/andersnoren.se/teman/",
        subtitle: "WordPress themes for bloggers, photographers, designers and businesses",
        // id: "6",
        link: "https://andersnoren.se/teman/",
				id: "wordpressthemes"
			},
			{
        title: "FREE UI resources",
        icon: "https://logo.clearbit.com/https://uidesigndaily.com/",
        subtitle: "Weekly FREE UI resources.",
        link: "https://uidesigndaily.com/",
				id: "freeuiresources"
      }
    ]
  },
  {
    name: "Patterns",
    catID: 6,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042743/tools-and-resources%20web/patterns_kkbjsg.svg",
    items: [
      {
        title: "Wow patterns",
        icon: "https://logo.clearbit.com/wowpatterns.com/",
        subtitle: "The best free handcrafted premium patterns and wallpapers.",
        link: "https://www.wowpatterns.com/",
				id: "wowpatterns"
      },
      {
        title: "Subtle patterns",
        icon: "https://www.toptal.com/designers/subtlepatterns/wp-content/themes/tweaker7/images/logo_subtle.svg",
        subtitle: "Free textures for your next web project.",
        link: "https://www.toptal.com/designers/subtlepatterns/thumbnail-view/",
				id: "subtlepatterns"
      },
      {
        title: "Hero Patterns",
        icon: "https://logo.clearbit.com/heropatterns.com",
        subtitle: "A collection of repeatable SVG background patterns for you to use on your web projects.",
        link: "http://www.heropatterns.com",
				id: "HeroPatterns"
      },
      {
        title: "Cool Backgrounds",
        icon: "https://logo.clearbit.com/coolbackgrounds.io/",
        subtitle: "Cool Backgrounds is a collection of tools to create compelling, colorful images for blogs, social media, and websites.",
        link: "http://coolbackgrounds.io/",
				id: "coolbackgrounds"
      },
      {
        title: "Patternizer",
        icon: "https://logo.clearbit.com/patternizer.com/",
        subtitle: "Stripe pattern generating tool",
        link: "https://patternizer.com/v60m",
				id: "patternizer"
      },
			{
				title: "Pattern CSS",
        icon: "https://d33wubrfki0l68.cloudfront.net/78a40466d76ea4f81b0bcb137ab25e6447eed3f6/5568e/assets/images/logo-white.jpg",
        subtitle: "CSS only library to fill your empty background with beautiful patterns.",
        link: "https://bansal.io/pattern-css",
				id: "patternCSS"
			},
			{
				title: "CSS3 Patterns",
        icon: "https://d33wubrfki0l68.cloudfront.net/78a40466d76ea4f81b0bcb137ab25e6447eed3f6/5568e/assets/images/logo-white.jpg",
        subtitle: "CSS only library to fill your empty background with beautiful patterns.",
        link: "https://leaverou.github.io/css3patterns/",
				id: "css3patterns"
			}
    ]
  },
  {
    name: "Illustrations",
    catID: 7,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/illustrations2_qxioz8.svg",
    items: [
      {
        title: "Mixkit",
        icon: "https://logo.clearbit.com/mixkit.co",
        subtitle: "Beautiful art & illustration.",
        // id: "1",
        link: "https://mixkit.co/art/",
				id: "mixkit"
      },
      {
        title: "unDraw Illustrations",
        icon: "https://logo.clearbit.com/undraw.co/",
        subtitle: "Take advantage of the on-the-fly color image generation to match your brand identity.",
        // id: "2",
        link: "https://undraw.co/illustrations",
				id: "undrawillu"
      },
      {
        title: "icons8 Illustrations",
        icon: "https://logo.clearbit.com/icons8.com/",
        subtitle: "Free Vector Illustrations to Class up Your Project.",
        // id: "3",
        link: "https://icons8.com/ouch",
				id: "icons8illu"
      },
      {
        title: "Humaaans",
        icon: "https://logo.clearbit.com/humaaans.com/",
        subtitle: "Mix-&-match illustrations of people with a design library.",
        // id: "4",
        link: "https://www.humaaans.com/",
				id: "humaaans"
      },
      {
        title: "Blush",
        icon: "https://logo.clearbit.com/https://blush.design/",
        subtitle: "Create, mix, and customize illustrations made by artists around the world.",
        // id: "4",
        link: "https://blush.design/	",
				id: "blush"
			},
			{
        title: "illustrations for 404 pages",
        icon: "https://logo.clearbit.com/https://error404.fun/",
        subtitle: "Make a unique journey through 404 error pages on your website.",
        // id: "4",
        link: "https://error404.fun/	",
				id: "illufor404pages"
      }
    ]
  },
  {
    name: "Generators",
    catID: 8,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568048895/tools-and-resources%20web/generator_eiuj8s.svg",
    items: [
      {
        title: "Blob maker",
        icon: "https://logo.clearbit.com/blobmaker.app/",
        subtitle: "SVG Blob maker.",
        link: "https://www.blobmaker.app/",
				id: "blobmaker"
      },
      {
        title: "Get waves",
        icon: "https://logo.clearbit.com/getwaves.io",
        subtitle: "Create SVG waves for your next project.",
        link: "https://getwaves.io",
				id: "Getwaves"
      },
      {
        title: "Clip Path Generator",
        icon: "https://www.uplabs.com/logos/uplabs/default--color.svg",
        subtitle: "Clip path generator.",
        link: "https://www.uplabs.com/posts/clip-path-generator",
				id: "ClipPathGenerator"
			},
			{
        title: "CSS Gradient",
        icon: "https://cssgradient.io/images/logo-55c31c59.svg",
        subtitle: "Free tool that lets you create a gradient background for websites.",
        link: "https://cssgradient.io/",
				id: "cssgradient"
      },
			{
        title: "Neumorphism",
        icon: "https://logo.clearbit.com/https://neumorphism.io/",
        subtitle: "Generate Soft-UI CSS code.",
        link: "https://neumorphism.io/",
				id: "neumorphism"
      },
			{
        title: "Custom Shape Dividers",
        icon: "https://shapedivider.app/img/logo_500x500.18aca7aa.png",
        subtitle: "Easier for designers and developers to export a beautiful SVG shape divider.",
        link: "https://www.shapedivider.app/",
				id: "customshapedividers"
      },
			{
        title: "Privacy Policy Generator",
        icon: "https://logo.clearbit.com/https://getterms.io/",
        subtitle: "Generate a custom Privacy Policy and Terms of Service statement for your website in minutes.",
        link: "https://getterms.io/",
				id: "privacypolicygen"
      },
			{
        title: "Responsive image breakpoints generators",
        icon: "https://logo.clearbit.com/https://responsivebreakpoints.com/",
        subtitle: "Easily generate the optimal responsive image dimensions.",
        link: "https://responsivebreakpoints.com/",
				id: "imagebreakpointsgen"
      },
			{
        title: "The Hero Generator",
        icon: "https://hero-generator.netlify.app/favicon.ico",
        subtitle: "Generates the code for a website header.",
        link: "https://hero-generator.netlify.app/",
				id: "theherogenerator"
      },
			{
        title: "Tinkersynth",
        icon: "https://logo.clearbit.com/https://tinkersynth.com/",
        subtitle: "Generates the code for a website header.",
        link: "https://tinkersynth.com/",
				id: "tinkersynth"
      },
			{
        title: "Brumm",
        icon: "https://logo.clearbit.com/https://brumm.af/shadows",
        subtitle: "Make a smooth shadow.",
        link: "https://brumm.af/shadows",
				id: "brumm"
      },
			{
        title: "Sharpen Design",
        icon: "https://logo.clearbit.com/https://sharpen.design/",
        subtitle: "Design challenge generator.",
        link: "https://sharpen.design/",
				id: "sharpendesign"
      }
    ]
  },
  {
    name: "Animations",
    catID: 9,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568048946/tools-and-resources%20web/animations_tqc0v4.svg",
    items: [
      {
        title: "MicronJS",
        icon: "https://webkul.github.io/micron/assets/res/micron-logo.svg",
        subtitle: "microInteraction library built with CSS Animations and controlled by JavaScript",
        link: "https://webkul.github.io/micron/",
				id: "micronjs"
      },
      {
        title: "LAX",
        icon: "https://logo.clearbit.com/alexfox.dev",
        subtitle: "Awesome scroll effects",
        link: "https://alexfox.dev/laxxx/",
				id: "lax"
      },
      {
        title: "Animista",
        icon: "https://logo.clearbit.com/animista.net/",
        subtitle: "CSS Animations on demand",
        link: "http://animista.net/",
				id: "animista"
      },
      {
        title: "SVG Artista",
        icon: "https://logo.clearbit.com/svgartista.net/",
        subtitle: "Super handy SVG drawing animation tool",
        link: "https://svgartista.net/",
				id: "svgartista"
      },
      {
        title: "Vivus Instant",
        icon: "https://maxwellito.github.io/vivus-instant/assets/vivus_instant_logo_monochrome.svg",
        subtitle: "Inline SVG animations with css",
        link: "https://maxwellito.github.io/vivus-instant/",
				id: "vivusinstant"
      },
      {
        title: "Animate",
        icon: "https://logo.clearbit.com/https://keyframes.app/",
        subtitle: "Create basic or complex CSS @keyframe animations.",
        link: "https://keyframes.app/animate",
				id: "snimate"
      },
      {
        title: "Framer Motion",
        icon: "https://logo.clearbit.com/framer.com/",
        subtitle: "A production-ready motion library for React.",
        link: "https://framer.com/motion/",
				id: "cleanup",
				createdAt: "2023-02-06T20:45:00.171Z"
      }					
    ]
  },
  // {
  //   name: "Logos",
  //   catID: 10,
  //   icon:
  //     "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568049062/tools-and-resources%20web/logos_l7qkdo.svg",
  //   items: [
  //     {
  //       title: "Looka",
  //       icon: "https://logo.clearbit.com/looka.com/",
  //       subtitle: "Use Looka's AI-powered platform to design a logo",
  //       id: "1",
  //       link: "https://looka.com/",
	// id: "landingfolio"
  //     }
  //   ]
  // },
  {
    name: "Image Tools",
    catID: 11,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568292957/tools-and-resources%20web/image_tools_x4ajlm.svg",
    items: [
      {
        title: "Remove bg",
        icon: "https://logo.clearbit.com/remove.bg/",
        subtitle: "Remove Image Background",
        link: "https://www.remove.bg/",
				id: "removebg"
      },
      {
        title: "Screely",
        icon: "https://logo.clearbit.com/screely.com/",
        subtitle: "Instantly turn your screenshot into a beautiful mockup",
        link: "https://www.screely.com/",
				id: "screely"
      },
      {
        title: "Squoosh",
        icon: "https://logo.clearbit.com/squoosh.app",
        subtitle: "Make images smaller using best-in-class codecs, right in the browser",
        link: "https://squoosh.app/",
				id: "squoosh"
      },
      {
        title: "Photopea",
        icon: "https://logo.clearbit.com/www.photopea.com/",
        subtitle: "Online photo editor",
        link: "https://www.photopea.com/",
				id: "photopea"
      },
      {
        title: "Doka Photo",
        icon: "https://logo.clearbit.com/https://doka.photo/",
        subtitle: "Edit images without leaving your browser",
        link: "https://doka.photo/",
				id: "dokaphoto"
      },
      {
        title: "Upscale Media",
        icon: "https://logo.clearbit.com/www.upscale.media",
        subtitle: "Upscale and Enhance Your Images for FREE",
        link: "www.upscale.media",
				id: "upscale",
				createdAt: "2023-02-05T20:45:00.171Z"
      },
      {
        title: "SVGator",
        icon: "https://logo.clearbit.com/www.svgator.com/",
        subtitle: "The Easiest Way to Animate SVG",
        link: "https://www.svgator.com/",
				id: "svgator",
				createdAt: "2023-02-05T20:45:00.171Z"
      },
      {
        title: "Glitchy Image",
        icon: "https://logo.clearbit.com/glitchyimage.com/",
        subtitle: "Glitch image generator",
        link: "https://glitchyimage.com/",
				id: "Glitchy",
				createdAt: "2023-02-05T20:45:00.171Z"
      },
      {
        title: "Cleanup pictures",
        icon: "https://logo.clearbit.com/cleanup.pictures",
        subtitle: "Remove any unwanted object, defect, people or text from your pictures in seconds",
        link: "https://cleanup.pictures",
				id: "cleanup",
				createdAt: "2023-02-06T20:45:00.171Z"
      },
      {
        title: "Lorem space",
        icon: "https://logo.clearbit.com/lorem.space/",
        subtitle: "API for placeholder images",
        link: "https://lorem.space/",
				id: "cleanup",
				createdAt: "2023-02-06T20:45:00.171Z"
      }						
    ]
  },
  {
    name: "Design Tips",
    catID: 11,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1570116180/tools-and-resources%20web/sketch_yhmc8t.svg",
    items: [
      {
        title: "Better products",
        icon: "",
        subtitle: "84 cognitive biases you should exploit to design better products",
        link: "https://www.mobilespoon.net/2019/04/collection-cognitive-biases-how-to-use.html",
				id: "betterproducts"
			},
			{
        title: "UI Improvements",
        icon: "",
        subtitle: "8 tips to quickly improve your UIs",
        link: "https://uxdesign.cc/8-more-tips-to-quickly-improve-your-ui-designs-368fb3bea5ba",
				id: "uiimprovements"
      }
    ]
  }

];

export default posts;
